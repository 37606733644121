import { ButtonLinkOpacity, View, Image } from "app/native";
import classNames from "classnames";
import { Platform } from "react-native";

export default ({ vertical }: { vertical?: boolean }) => Platform.OS != "web" ? null : (
  <View className={classNames("flex", {
    "flex-row items-center justify-center": !vertical,
    "flex-col-reverse items-start": vertical,
  })}>
    <ButtonLinkOpacity href="https://play.google.com/store/apps/details?id=app.oase">
      <Image alt='Get it on Google Play' width={646} height={250} className={classNames("w-44", {
        "-ml-[10px]": vertical, // The image has some padding within it, so we need to offset it
      })} contentFit="contain" src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
    </ButtonLinkOpacity>
    <ButtonLinkOpacity href="https://apps.apple.com/us/app/oase/id6446152196?platform=iphone">
      <Image alt='Get it on App Store' width={120} height={40} className="w-36" contentFit="contain" src='/apple-app-store.svg' />
    </ButtonLinkOpacity>
  </ View>
)
