import { Link, Text, TouchableOpacity, View } from "app/native";
import { FontAwesomeIcon, IconProp } from 'app/icon'
import classNames from "classnames";
import { styled } from "nativewind";
import { useMemo } from "react";
import { LogoFour } from "./LogoFour";
import { MotiView } from "moti";
import Loading from "app/components/Loading";

type Mode = "primary" | "secondary" | "transparent" | "accent" | "primary-disabled" | "secondary-disabled" | "transparent-disabled" | "accent-disabled";
type Size = "small" | "large";

const Component = ({ style, children, transparent, secondary, accent, text, icon, iconLast, textClasses, iconClasses, loading, onPress, href, disabled, size = "large" }: { style?: any, children?: any, icon?: IconProp | "logo", iconLast?: boolean, text?: string | null, textClasses?: string, iconClasses?: string, onPress?: () => void, transparent?: boolean, secondary?: boolean, disabled?: boolean, size?: Size, href?: string, accent?: boolean, loading?: boolean }) => {

  const mode = useMemo(() => {
    const baseMode = transparent ? "transparent" : secondary ? "secondary" : accent ? "accent" : "primary";
    return disabled ? `${baseMode}-disabled` as Mode : baseMode as Mode;
  }, [transparent, secondary, disabled])

  const backgroundColor = useMemo(() => {
    if (loading) return "bg-white";

    switch (mode) {
      case "primary":
        return "bg-brand-primary shadow-sm";
      case "secondary":
        return "bg-brand-black-100 shadow-sm";
      case "accent":
        return "bg-brand-secondary-blue shadow-sm";
      case "transparent":
        return "bg-transparent";
      case "primary-disabled":
        return "bg-brand-primary/50";
      case "secondary-disabled":
        return "bg-brand-black-100";
      case "transparent-disabled":
        return "bg-transparent";
      case "accent-disabled":
        return "bg-brand-secondary-blue/40";
    }
  }, [mode, loading])

  const textColor = useMemo(() => {
    if (loading) return "text-transparent";

    switch (mode) {
      case "primary":
        return "text-white";
      case "secondary":
        return "text-brand-black-900";
      case "accent":
        return "text-white";
      case "transparent":
        return "text-brand-black-900";
      case "primary-disabled":
        return "text-white";
      case "secondary-disabled":
        return "text-brand-black-600";
      case "transparent-disabled":
        return "text-brand-black-600";
      case "accent-disabled":
        return "text-white/40";
    }
  }, [mode, loading])

  const logoFill = useMemo(() => {
    if (loading) return "fill-transparent";

    switch (mode) {
      case "primary":
        return "fill-white";
      case "secondary":
        return "fill-brand-black-900";
      case "accent":
        return "fill-white";
      case "transparent":
        return "fill-brand-black-900";
      case "primary-disabled":
        return "fill-white";
      case "secondary-disabled":
        return "fill-brand-black-600";
      case "transparent-disabled":
        return "fill-brand-black-600";
      case "accent-disabled":
        return "fill-white/40";
    }
  }, [mode, loading])

  const classes = classNames("flex flex-row items-center justify-center hover:shadow-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-primary", backgroundColor, {
    "py-2 px-6 rounded-lg": size === "large",
    "py-1 px-4 rounded": size === "small",
  })

  const useTextClasses = classNames("font-t-semi tracking-wider", textColor, {
    "ml-3": icon && !iconLast,
    "mr-3": icon && iconLast,
    "text-xl": size === "large",
    "text-base": size === "small",
  }, textClasses)

  const useIconClasses = classNames({
    "w-6 h-6": size === "large",
    "w-5 h-5": size === "small",
  }, textColor, iconClasses)

  const renderIcon = useMemo(() => {
    if (!icon) return null;

    if (icon === "logo") {
      return (
        <View className={useIconClasses}>
          <LogoFour size="small" fill={logoFill} />
        </View>
      );
    } else {
      return <FontAwesomeIcon icon={icon} className={useIconClasses} />;
    }
  }, [icon, useIconClasses, logoFill])

  const childrenOrtextAndIcon = useMemo(() => {
    return children ? children : (
      <>
        {icon && !iconLast ? renderIcon : null}
        <Text className={useTextClasses}>{text}</Text>
        {icon && iconLast ? renderIcon : null}
      </>
    )
  }, [text, icon, children, disabled, renderIcon])

  const renderLoading = useMemo(() => {
    const width = size === "small" ? 24 : 24;
    return (
      <MotiView
        from={{
          opacity: 0,
          scale: 0,
        }}
        animate={{
          opacity: loading ? 1 : 0,
          scale: loading ? 1 : 0,
        }}
        transition={{
          type: 'timing',
          duration: 300,
        }}
        style={{
          position: "absolute",
          display: "flex",
          marginLeft: -4,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <View className="" style={{}}>
          {loading ? <Loading delay={300} size={width} /> : null}
        </View>
      </MotiView>
    )
  }, [loading, size])

  return href ? (
    <Link key={disabled ? "a" : "b"} href={href} className={classes} style={style}>
      {
        childrenOrtextAndIcon
      }
      {renderLoading}
    </Link>

  ) : (
    <TouchableOpacity key={disabled ? "a" : "b"} disabled={disabled || loading} className={classes} onPress={(disabled || loading) ? () => { } : onPress} style={style} >
      {
        childrenOrtextAndIcon
      }
      {renderLoading}
    </TouchableOpacity >
  );
};

export const Button = styled(Component);

