import Svg, { Path } from 'react-native-svg';
import { styled } from 'nativewind'

const StyledSvg = styled(Svg);
const StyledPath = styled(Path, { classProps: ["fill"] });

type SizeType = "icon" | "small" | "medium" | "large";

export const LogoFour = ({ fill, size }: { fill: string[] | string, size: SizeType }) => {
  if (typeof fill === "string") {
    fill = [fill, fill, fill, fill];
  }

  switch (size) {
    case "icon":
      return (
        <StyledSvg className="web:h-full web:w-full"
          viewBox="0 0 67.9 68.06"
        >
          <StyledPath
            d="m.86,22.02c1.08,3.31,3.41,5.89,6.65,7.19,3.29,1.32,7.06,1.26,10.55,1.07,4.97-.39,9.19-2.64,11.13-7.42.97-2.37,1.18-5.13,1.17-7.67.01-2.54-.2-5.29-1.17-7.67C27.25,2.75,23.03.5,18.06.11c-.65-.04-1.31-.07-1.98-.08v-.03H0v15.2c0,2.23.15,4.69.86,6.82Z"
            fill={fill[0]} />
          <StyledPath
            d="m67.04,8.39c-1.08-3.31-3.41-5.89-6.65-7.19C57.09-.11,53.32-.05,49.84.13c-4.97.39-9.19,2.64-11.13,7.42-.97,2.37-1.18,5.13-1.17,7.67-.01,2.54.2,5.29,1.17,7.67,1.94,4.78,6.16,7.03,11.13,7.42.65.04,1.31.07,1.98.08v.03h16.08v-15.2c0-2.23-.15-4.69-.86-6.82Z"
            fill={fill[1]} />
          <StyledPath
            d="m38.4,46.04c1.08-3.31,3.41-5.89,6.65-7.19,3.29-1.32,7.06-1.26,10.55-1.07,4.97.39,9.19,2.64,11.13,7.42.97,2.37,1.18,5.13,1.17,7.67.01,2.54-.2,5.29-1.17,7.67-1.94,4.78-6.16,7.03-11.13,7.42-.65.04-1.31.07-1.98.08v.03h-16.08v-15.2c0-2.23.15-4.69.86-6.82Z"
            fill={fill[2]} />
          <StyledPath
            d="m29.5,59.67c-1.08,3.31-3.41,5.89-6.65,7.19-3.29,1.32-7.06,1.26-10.55,1.07-4.97-.39-9.19-2.64-11.13-7.42C.2,58.13-.01,55.38,0,52.84c-.01-2.54.2-5.29,1.17-7.67,1.94-4.78,6.16-7.03,11.13-7.42.65-.04,1.31-.07,1.98-.08v-.03h16.08v15.2c0,2.23-.15,4.69-.86,6.82Z"
            fill={fill[3]} />
        </StyledSvg>
      )
    case "small":
      return (
        <StyledSvg className="web:h-full web:w-full" viewBox="0 0 33 33">
          <StyledPath d="M0.43 10.92C0.96 12.56 2.12 13.84 3.73 14.48C5.36 15.13 7.23 15.1 8.96 15.01C11.42 14.82 13.52 13.7 14.48 11.33C14.96 10.15 15.06 8.79 15.06 7.53C15.06 6.27 14.96 4.91 14.48 3.73C13.51 1.36 11.42 0.25 8.96 0.06C8.64 0.04 8.31 0.03 7.98 0.02L0 0V7.53C0 8.64 0.07 9.86 0.43 10.91V10.92Z" fill={fill[0]} />
          <StyledPath d="M32.01 4.15999C31.48 2.51999 30.32 1.23999 28.71 0.599991C27.07 -0.0500093 25.2 -0.0300094 23.48 0.0699906C21.02 0.259991 18.92 1.37999 17.96 3.74999C17.48 4.92999 17.38 6.28999 17.38 7.54999C17.38 8.80999 17.48 10.17 17.96 11.35C18.92 13.72 21.01 14.84 23.48 15.03C23.8 15.05 24.13 15.06 24.46 15.07L32.43 15.08V7.54999C32.43 6.43999 32.36 5.21999 32 4.16999L32.01 4.15999Z" fill={fill[1]} />
          <StyledPath d="M17.8 21.59C18.33 19.95 19.49 18.67 21.1 18.03C22.73 17.38 24.6 17.41 26.33 17.5C28.79 17.69 30.89 18.81 31.85 21.18C32.33 22.36 32.43 23.72 32.43 24.98C32.43 26.24 32.33 27.6 31.85 28.78C30.89 31.15 28.8 32.27 26.33 32.46C26.01 32.48 25.68 32.49 25.35 32.5L17.38 32.51V24.98C17.38 23.87 17.45 22.65 17.81 21.6L17.8 21.59Z" fill={fill[2]} />
          <StyledPath d="M14.63 28.35C14.1 29.99 12.94 31.27 11.33 31.91C9.7 32.56 7.83 32.53 6.1 32.44C3.64 32.25 1.54 31.13 0.58 28.76C0.1 27.59 0 26.23 0 24.97C0 23.71 0.1 22.35 0.58 21.17C1.54 18.8 3.63 17.68 6.1 17.49C6.42 17.47 6.75 17.46 7.08 17.45L15.05 17.44V24.97C15.05 26.08 14.97 27.3 14.62 28.35H14.63Z" fill={fill[3]} />
        </StyledSvg>
      );

    case "medium":
      return (
        <StyledSvg className="web:h-full web:w-full" viewBox="0 0 125 126">
          <StyledPath d="M1.70001 43.67C3.84001 50.24 8.46001 55.35 14.9 57.92C21.43 60.53 28.91 60.41 35.82 60.04C45.68 59.27 54.05 54.81 57.89 45.33C59.82 40.62 60.23 35.16 60.2 30.13C60.23 25.09 59.81 19.63 57.89 14.93C54.05 5.46001 45.67 0.990005 35.82 0.220005C34.53 0.150005 33.21 0.0900055 31.89 0.0500055V-0.00999451H9.88302e-06V30.13C-0.0199901 34.56 0.30001 39.44 1.70001 43.66V43.67Z" fill={fill[0]} />
          <StyledPath d="M123.02 16.65C120.88 10.08 116.26 4.97 109.82 2.4C103.29 -0.220001 95.81 -0.100001 88.9 0.269999C79.04 1.04 70.67 5.5 66.83 14.98C64.9 19.69 64.49 25.15 64.52 30.18C64.49 35.22 64.91 40.68 66.83 45.38C70.67 54.86 79.05 59.32 88.9 60.09C90.19 60.16 91.51 60.22 92.83 60.26V60.32H124.72V30.18C124.74 25.75 124.42 20.87 123.02 16.65Z" fill={fill[1]} />
          <StyledPath d="M66.22 81.37C68.36 74.8 72.98 69.69 79.42 67.12C85.95 64.51 93.43 64.63 100.34 65C110.2 65.77 118.57 70.23 122.41 79.71C124.34 84.42 124.75 89.88 124.72 94.91C124.75 99.95 124.33 105.41 122.41 110.11C118.57 119.59 110.19 124.05 100.34 124.82C99.05 124.89 97.73 124.95 96.41 124.98V125.04H64.52V94.9C64.5 90.47 64.82 85.59 66.22 81.37Z" fill={fill[2]} />
          <StyledPath d="M58.51 108.4C56.37 114.97 51.75 120.08 45.31 122.65C38.78 125.26 31.3 125.14 24.39 124.77C14.53 124 6.16 119.54 2.32 110.06C0.389998 105.36 -0.0200016 99.9 -1.62597e-06 94.86C-0.0300016 89.82 0.389998 84.36 2.31 79.66C6.15 70.18 14.53 65.72 24.38 64.95C25.67 64.88 26.99 64.82 28.31 64.78V64.72H60.2V94.86C60.22 99.29 59.9 104.17 58.5 108.39L58.51 108.4Z" fill={fill[3]} />
        </StyledSvg>
      );

    case "large":
      return (
        <StyledSvg className="web:h-full web:w-full" viewBox="0 0 247 248">
          <StyledPath d="M3.40997 87.35C7.68997 100.49 16.94 110.7 29.81 115.86C42.87 121.08 57.83 120.85 71.65 120.11C91.36 118.57 108.11 109.64 115.8 90.69C119.65 81.28 120.47 70.36 120.43 60.28C120.49 50.2 119.65 39.28 115.8 29.87C108.11 10.92 91.35 1.98 71.64 0.45C69.05 0.31 66.43 0.19 63.78 0.12V0H-3.20484e-05V60.28C-0.030032 69.14 0.599968 78.9 3.40997 87.35Z" fill={fill[0]} />
          <StyledPath d="M243.04 33.29C238.76 20.15 229.51 9.94 216.64 4.78C203.59 -0.439999 188.63 -0.199999 174.81 0.530001C155.1 2.07 138.35 11 130.66 29.95C126.81 39.36 125.99 50.28 126.03 60.36C125.97 70.44 126.81 81.36 130.66 90.77C138.34 109.72 155.1 118.65 174.81 120.19C177.4 120.33 180.02 120.45 182.67 120.52V120.64H246.45V60.36C246.48 51.5 245.85 41.74 243.04 33.29Z" fill={fill[1]} />
          <StyledPath d="M129.43 159.74C133.71 146.6 142.96 136.39 155.83 131.23C168.89 126.01 183.85 126.24 197.67 126.98C217.38 128.52 234.13 137.45 241.82 156.4C245.67 165.81 246.49 176.73 246.45 186.81C246.51 196.89 245.67 207.81 241.82 217.22C234.14 236.17 217.38 245.1 197.67 246.64C195.08 246.78 192.46 246.9 189.81 246.97V247.09H126.03V186.81C126 177.95 126.63 168.19 129.44 159.74H129.43Z" fill={fill[2]} />
          <StyledPath d="M117.01 213.79C112.73 226.93 103.48 237.14 90.61 242.3C77.55 247.52 62.59 247.29 48.77 246.55C29.06 245.01 12.31 236.08 4.62 217.13C0.779996 207.72 -0.0400036 196.8 -3.58443e-06 186.72C-0.0600036 176.64 0.779996 165.72 4.63 156.31C12.31 137.36 29.07 128.43 48.78 126.89C51.37 126.75 53.99 126.63 56.64 126.56V126.44H120.42V186.72C120.45 195.58 119.82 205.34 117.01 213.79Z" fill={fill[3]} />
        </StyledSvg>
      );
  }
};

